import { useEffect, useMemo, useState } from 'react';
import { posterUrl, setPropertyToCloudinaryUrl } from '../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../store/store';
import { getStoryById } from '../../../store/slices/stories';
import { AppRoutes, getApiUrlWithIdQueryParam } from '../../../common/constants/routes';
import { StoryEndpoints, getApiUrlForId } from '../../../api/endpoints';
import { httpClient } from '../../../services/httpClient/httpClient';
import { googleStorageVideoUrlToCloudinaryUrl } from '../../VideoToolPage/utils';
import { DownloadType } from '../types';
import { useRefetchStoryUntilModerated } from '../../../services/hooks/useRefetchStoryUntilModerated';
import { StoriesApiModel } from '../../../api/models/stories';
import { getUserIncentiveCampaigns } from '../../../store/slices/rewards';

export type SharePlatformType =
  | 'LINK'
  | 'SMS'
  | 'FACEBOOK'
  | 'LINKEDIN'
  | 'TWITTER'
  | 'PINTEREST'
  | 'INSTAGRAM'
  | 'TIKTOK'
  | 'REELS'
  | 'REDDIT'
  | 'DOWNLOAD';

export const trackSharesCall = async (id: string, platform: SharePlatformType) => {
  try {
    return httpClient.post<{ platform: string }, { success: boolean }>({
      url: getApiUrlForId(StoryEndpoints.TrackShareClicks, id),
      payload: { platform },
      requiresToken: true,
    });
  } catch (error) {
    console.log(error);
  }
};

interface UseShareStoryArgs {
  storyId?: string;
  refetchUntilModerated?: boolean;
}

export const useShareStory = ({ storyId, refetchUntilModerated }: UseShareStoryArgs) => {
  const [downloadingType, setDownloadingType] = useState<DownloadType | null>(null);

  const user = useTypedSelector((state) => state.me);

  const {
    shareStory,
    stories: { isLoading: isStoryLoading },
  } = useTypedSelector((state) => state.stories);

  const dispatch = useAppDispatch();
  const { refetchStoryUntilModerated } = useRefetchStoryUntilModerated();

  const storyThumbnail = useMemo(() => {
    if (!shareStory) return '';
    return shareStory.thumbnailUrl || posterUrl(shareStory.url || '');
  }, [shareStory]);

  // fetch story and run refetch process if needed
  const initStory = async () => {
    if (shareStory?.id === storyId) return;

    if (storyId) {
      const res = await dispatch(getStoryById({ id: storyId }));
      const story = res?.payload as StoriesApiModel;

      if (story.id && refetchUntilModerated) {
        refetchStoryUntilModerated(story);
      }
    }
  };

  useEffect(() => {
    initStory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyId]);

  const storyLink = useMemo(() => {
    if (typeof window === 'undefined' || !window?.location?.origin || !shareStory) return;

    return window.location.origin.concat(
      getApiUrlWithIdQueryParam(AppRoutes.StoryLink, shareStory.shortcode || shareStory.id),
    );
  }, [shareStory]);

  const trackShare = async (platform: SharePlatformType) => {
    // user token is required by api
    if (!user.id || !shareStory) return;
    await trackSharesCall(shareStory.id, platform);
    const userId = httpClient.getUserId();
    if (userId) {
      dispatch(getUserIncentiveCampaigns({ userId }));
    }
  };

  const downloadUrl = useMemo(() => {
    if (!shareStory) return;

    const url = googleStorageVideoUrlToCloudinaryUrl(shareStory.url);
    return setPropertyToCloudinaryUrl(url, 'fl_attachment');
  }, [shareStory]);

  const downloadStory = async (downloadType: DownloadType) => {
    if (!downloadUrl) return;

    setDownloadingType(downloadType);

    const a = document.createElement('a');
    a.download = `${user.name}'s story`;
    a.href = downloadUrl;

    trackShare(downloadType.toUpperCase() as SharePlatformType);

    // prefetch download url
    await fetch(downloadUrl);

    a.click();

    setDownloadingType(null);
  };

  const isLoading = isStoryLoading || !shareStory;

  return {
    shareStory,
    isLoading,
    storyThumbnail,
    storyLink,
    trackShare,
    downloadStory,
    downloadingType,
    isShareStoryLoading: isStoryLoading,
  };
};
